.select-base-map-container {
    .select-base-map {
        width: 250px;
        right: -126px;
        top: 20px;

        label {
            font-size: 0.9rem;
        }

        .opacity-range-control {
            margin-top: 5px;
        }
    }
}
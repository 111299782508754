.look-up-planning-container {
    .left-menu {
        padding: 10px 0;

        .search-container {
            margin-top: 10px;
            margin-bottom: 10px;

            .search-by-my-position {
                top: 5px;
                border-radius: 4px;
            }
        }

        .list-select-side-menu {
            a {
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                background-color: #2075c4;
                text-decoration: none;
                cursor: pointer;
                border-bottom: 1px solid #ffffff;

                &.active-custom {
                    background-color: #35B6DE;
                }
                &:hover {
                    background-color: #35B6DE;
                }
            }
        }

        .slAutocomplete {
            .select__placeholder {
                font-size: 0.875rem;
            }
        }

        .search-container {
            input {
                font-size: 0.875rem;
            }
        }
    }
}
#tooltip-openlayer {
    position: absolute;
    opacity: 0;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    z-index: 15;
    width: 8rem;
    height: 7.5rem;

    p {
        color: #000;
        font-size: 12px;
        text-align: center;
    }
}

.flash-animation {
    position: fixed;
    border-radius: 50%;
    border: 2px solid #3B82F4;
    height: 60%;
    width: 60%;
    top: 20%;
    left: 20%;
}

.flash-animation1 {
    position: fixed;
    border-radius: 50%;
    border: 2px solid #3B82F4;
    height: 30%;
    width: 30%;
    top: 35%;
    left: 35%;
}

@keyframes flash {
    from {
        width: 15px;
        height: 15px;
    }

    to {
        width: 40px;
        height: 40px;
        opacity: .2;
    }
}
.banner {

  position: relative;
  background: url('./CanTho1920.jpg') no-repeat top center;
  height: 795px;

  p {
    color: #fff;
  }

  .banner_bg {
    position: absolute;
    //background: linear-gradient(90deg, rgba(2, 41, 126, 0.8) 0%, rgba(2, 41, 126, 0.9) 0.01%, rgba(66, 95, 159, 0.7) 51.04%, rgba(255, 255, 255, 0.7) 100%);
    background: rgb(32, 73, 239);
    background: linear-gradient(90deg, rgba(32, 73, 239, 1) 0%, rgba(32, 73, 239, 0) 100%);
    height: 795px;
    width: 100%;

    // display: flex;
    // justify-content: center;
    //align-items: center;
    .banner_content {
      height: 795px;
      max-width: 1400px;
      display: flex;
      margin: auto;
      align-items: center;

      .left_banner {
        padding: 0 2rem;

        .banner_title {
          margin-bottom: 3vh;

          p {
            margin-bottom: 1vh;
            font-size: 2.7vh;
            font-weight: 400;
            text-shadow: 2px 2px 5px gray ;
          }
          .banner_title2 {
            font-size: 4vh;
            font-weight: 500;
          }
          .banner_title1 {
            font-size: 6.5vh;
            font-weight: 600;
            color: #C2E20C;
            sup {
              font-weight: 300;
              color: #fff;
            }
          }
        }

        .banner_search {
          display: flex;
          width: 46px;

          .search_icon {
            cursor: pointer;
            margin-right: 0.5rem;
            padding: 8px;
            background-color: #1954a7;
            border-radius: 50%;

            &:hover {
              background-color: #C2E20C;
            }
            img {
              width: 30px;
            }
          }

          .active {
            background-color: #C2E20C;
          }
        }

        .search_input {
          margin: 1rem 0 3rem 0;

          ::placeholder {
            /* chạy tốt trên Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1;
            /* Firefox */
          }

          .search_input_select {
            padding: 10px 0 16px 10px;
            width: 27rem;
            background-color: #f8f9fa4a;
            border: 1px solid #F8F8F8;
            border-radius: 3px;

            input {
              color: #fff !important;
            }

            .css-1wa3eu0-placeholder {
              color: #fff;

              input {
                color: #fff;
              }
            }
          }

          .search_input_coord {
            padding: 16px 15px 14px 20px;
            width: 27rem;
            background-color: #f8f9fa4a;
            border: 1px solid #F8F8F8;
            border-radius: 3px;
            color: #fff;

          }
        }

        .banner_blocks {
          display: flex;

          .block_item {
            padding-right: 3.5rem;

            img {
              cursor: pointer;
            }

            p {
              cursor: pointer;
              padding-top: 0.5rem;
              text-align: center;
            }
          }
        }
      }

      .right_banner {
        margin-top: -66px;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .banner {
    height: 481px;
    background: url('./CanTho1024.jpg') no-repeat top center;

    .banner_bg {
      height: 481px;

      .banner_content {
        height: 481px;
        display: flex;
        margin: auto;
        align-items: center;

        .left_banner {
          text-align: center;
          
          // .banner_title {
          //   margin-bottom: 2rem;

          //   p {
          //     font-size: 1.3rem;
          //     margin-bottom: 5px;
          //   }

          //   .banner_title1 {
          //     padding-left: 8rem;
          //     font-size: 2rem;
          //   }
          // }

          .banner_search {
            display: flex;
            margin-left: 2.5rem;
            width: 24px;

            .search_icon {
              padding: 6px 8px;

              img {
                width: 20px;
                height: 20px;
              }
            }

            .active {
              background-color: #C2E20C;
            }
          }

          .search_input {
            ::placeholder {
              font-size: 14px;
            }

            p {
              font-size: 14px;
            }

            margin: 1rem 0 2rem 2.5rem;

            .search_input_select {
              padding: 3px 0 9px 6px;
              width: 20rem;
              background-color: #f8f9fa4a;
              border: 1px solid #F8F8F8;
              border-radius: 3px;
              font-size: 14px;

              input {
                color: #fff !important;
              }

              .css-1wa3eu0-placeholder {
                color: #fff;
                font-size: 14px;

                input {
                  color: #fff;
                }
              }
            }

            .search_input_coord {
              padding: 10px 9px 9px 16px;
              width: 20rem;
              background-color: #f8f9fa4a;
              border: 1px solid #F8F8F8;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;

            }
          }

          .banner_blocks {
            margin-left: 2rem;

            .block_item {
              padding-right: 1.5rem;

              img {
                width: 5rem;
                display: flex;
                margin: auto;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    height: 795px;
    background: url('./CanTho1920.jpg') no-repeat top center;

    .banner_bg {
      height: 795px;

      .banner_content {
        height: 795px;
        display: flex;
        margin: auto;
        align-items: center;

        .left_banner {
          .banner_title {
            margin: 2rem 0;

            p {
              font-size: 1.3rem;
              margin-bottom: 5px;
              text-align: center;
              text-shadow: 2px 2px 5px #2048EF;
            }

            .pl-5 {
              padding-left: 0 !important;
            }

            .banner_title1 {
              padding-left: 0 !important;
              font-size: 2.5rem;
            }
          }
          .banner_search {
            margin: 0;
          }
          .search_input {
              margin: 1rem 0 3rem 0;
          }
          .banner_blocks {
            margin-left: 0;

            .block_item {
              padding-right: 2.3rem;

              img {
                width: 5rem;
                display: flex;
                margin: auto;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }

      }
    }
  }
}
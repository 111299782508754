.planning-introduce_container {
  padding: 20px 0 0 10px;

  .planning_banner {
    position: relative;
    margin-bottom: 1rem;

    .planning_img_banner {
      img {
        width: 100%;
        max-height: 500px;
      }
    }

    .planning_introduce_block {
      position: absolute;
      bottom: 3px;
      width: 100%;

      .planning_introduce_name {
        width: 90%;
        margin: auto;
        background: rgb(40, 110, 253);
        background: linear-gradient(0deg, rgba(40, 110, 253, 1) 0%, rgba(0, 0, 0, 0.7259278711484594) 0%);
        color: #fff;
        border-bottom: 2px solid #C2E20C;

        .introduce_name_ {
          font-size: 1.5rem;
          font-weight: 500;
          display: inline-block;
          margin-top: 1.1rem;
          padding-left: 1.1rem;
        }

        .link_to_map {
          display: inline-block;
          float: right;
          margin: 1.4rem 1rem;

          a {
            padding: 10px;
            background: linear-gradient(90deg, #1A77C7 0%, #00B6DE 100%);
            border-radius: 2px;
            color: #fff;

            &:hover {
              text-decoration: none;
              color: #C2E20C;
            }
          }
        }
      }
    }

  }
  .indexing {
    margin: 1rem;
    a{
      &:hover {
        text-decoration: none;
      }
    }
  }
  .planning_introduce_info {
    .planning_area {
      h5 {
        font-size: 2rem;
        font-weight: 700;
        //color: #33AFDB;
        margin-top: 1rem;
        background: -webkit-linear-gradient(#2479C8, #33AFDB);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p {
        padding-top: 1rem;
        border-top: 1px solid #31A8D8;
      }
    }

    .planning_introduce_item {
      text-align: center;
      padding: 0 3rem;

      img {
        margin-bottom: 1rem;
      }

      h6 {
        padding-top: 1rem;
        border-top: 1px solid #31A8D8;
        text-transform: uppercase;
      }
    }
  }

  .planning_event {
    padding: 1rem;

    .planning_event_tile {
      text-align: center;

      p {
        background-color: #2479C8;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px;
      }
    }

    .event_slide {
      .event_slide_item {
        img {
          padding: 0 5px;
          margin-top: 1rem;
          width: 100%;
        }

        .event_description_item {
          margin: 0 5px;

          p {
            padding: 10px;
            background-color: #F1F1F1;
          }
        }

        .event_date_item {
          border-bottom: 2px solid #2479C8;

          p {
            font-size: 1.2rem;
            font-weight: 500;
            color: #2479C8;
            margin-bottom: .5rem;
            text-align: center;
          }
        }

        .event_date_dote {
          margin: auto;
          position: relative;
          top: 9px;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #C2E20C;
        }
      }

    }

    .planning_event_date {
      margin: 0;
      text-align: center;


    }

    .planning_event_content {
      margin-top: 1rem;
    }
  }

  .planning_infrastructure {
    padding: 1rem;

    .planning_infrastructure_title {
      text-align: center;
      margin-bottom: 2rem;

      p {
        background-color: #2479C8;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px;
      }
    }

    .planning_slide {
      img {
        width: 100%;
        max-height: 500px;
      }
    }

    .infrastructure_content {
      margin-top: 2rem;
      text-align: center;

      img {
        margin-bottom: 1rem;
      }
    }
  }

  .file_video {
    margin-top: 1rem;

    .file_video_title {
      text-align: center;

      .is_file,
      .is_video {
        width: 50%;
        display: inline-block;
        cursor: pointer;
      }

      p {
        background-color: #F1F1F1;
        color: #000;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px;
      }

      .is_active {
        p {
          background-color: #2479C8;
          color: #fff;
        }
      }
    }
  }

  .introduce_content {
    img {
      width: 100%;
      height: 100%;
    }

    text-align: justify;
  }


  .planning_introduce_content {
    padding: 1rem;

    .introduce_content_title {
      text-align: center;

      p {
        background-color: #2479C8;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px;
      }
    }

    .description {
      margin-top: 1rem;

      p {
        color: gray;
        font-weight: 500;
        font-size: 1.1rem;
        font-style: italic;
      }
    }
  }

  .file_video_content {
    margin-bottom: 2rem;

    .file_item {
      img {
        width: 100%;
        border-radius: 5px;
      }

      .file_item_dl {
        display: flex;
      }

      p {
        margin-top: 10px;
        word-wrap: break-word;
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        min-height: 45px;
      }

      a {
        margin: auto;
        border: 1px solid #1A77C7;
        border-radius: 50px;
        padding: 5px 20px;
        color: #1A77C7;

        &:hover {
          text-decoration: none;
          color: #fff;
          background: linear-gradient(90deg, rgba(26, 119, 199, 1) 0%, rgba(0, 182, 222, 1) 100%);
        }
      }
    }
  }

  .video_youtube {
    margin-bottom: 1rem;

    iframe {
      border-radius: 5px;
      height: 100%;
      width: 100%;
    }
  }

  .link_to_map {
    margin-bottom: 1.5rem;
  }
}

.col-2dot4,
// extra small
.col-sm-2dot4,
// small
.col-md-2dot4,
// medium
.col-lg-2dot4,
// large
.col-xl-2dot4 {
  // extra large
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 540px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.openlayer-map-view-container {

    #tooltip-container-openlayer {
        position: fixed;
        opacity: 0;
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        display: none;
    }

    .ol-dragbox {
        background-color: rgba(192, 68, 68, 0.4);
        border-color: rgba(100, 150, 0, 1);
    }

    #popup-container-openlayer {
        position: fixed;
        opacity: 0;
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        z-index:15;
    }

    #popup-color-sign {
        position: fixed;
        opacity: 1;
        padding: 5px;
        background-color: rgba(255, 255, 255,0.6);
        bottom: 5px;
        right: 10px;
        display: block;
        

        .sign-color-green {
            background-color: #12760A;
            width: 20px;
            height: 20px;
            display: inline-block;
        }

        .sign-color-yellow {
            background-color: #D3C00A;
            width: 20px;
            height: 20px;
            display: inline-block;
        }

        .sign-color-blue {
            background-color: #0F2293;
            width: 20px;
            height: 20px;
            display: inline-block;
        }

        .sign-color-red {
            background-color: #FF0000;
            width: 20px;
            height: 20px;
            display: inline-block;
        }

        span {
            display: inline;
        }
    }

    #mouse-position {
        left: 420px;
        position: fixed;
        bottom: 5px;
    }

    #title {
        font-weight: bold;
    }
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
#map-scale {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

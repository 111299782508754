.banner_search {
    display: flex;
    width: 46px;

    .search_icon {
        cursor: pointer;
        margin-right: 0.5rem;
        padding: 8px;
        background-color: #1954a7;
        border-radius: 50%;

        &:hover {
            background-color: #35B6DE;
        }

        img {
            width: 25px;
        }
    }

    .active {
        background-color: #35B6DE;
    }
}

.search_input {
    margin: 1rem 0;

    // ::placeholder {
    //   /* chạy tốt trên Chrome, Firefox, Opera, Safari 10.1+ */
    //   color: #fff;
    //   opacity: 1;
    //   /* Firefox */
    // }

    .search_input_select {
        padding: 5px 0 11px 0;
        width: 100%;
        background-color: #f8f9fa4a;
        border: 1px solid #000000;
        border-radius: 3px;
    }

    .search_input_coord {
        padding: 11px 15px 9px 10px;
        width: 100%;
        background-color: #f8f9fa4a;
        border: 1px solid #000000;
        border-radius: 3px;

    }
}
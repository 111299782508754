.planning_home {
    background: #ffffff;
    margin-bottom: 3rem;

    .container {
        .planning_title {
            margin: 10px 0;
            display: flex;
            justify-items: center;
            align-items: center;

            p {
                margin: auto;
                color: #3342B9;
                font-size: 1.5rem;
                font-weight: 500;
                border-bottom: 3px solid #FFAF18;
            }
        }

        .planning_slide {
            margin-top: 1rem;
            position: relative;
            padding: 0 20px;
            &:hover {
                transform: scale(1.02) !important;
            }
            .planning__slide {
                
                img {
                    width: 100%;
                    max-height: 400px;
                    min-height: 293px;
                    border-radius: 10px;
                }

                .planning_content {
                    //background-color: #2049EF;
                    //min-height: 400px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 10;
                    padding: 0 20px;

                    .planning__content {
                        background: linear-gradient(rgba(29, 41, 62, 0) 0%, rgb(18, 36, 66) 100%);
                        height: 150px;
                        border-radius: 10px;
                        text-align: center;

                        .planning_name {
                            padding: 0 5px !important;
                            color: #ffffff;
                            font-size: 1.5rem;
                            font-weight: 500;

                            p {
                                margin-bottom: 5px !important;
                            }
                        }

                        .planning_description {
                            padding: 0 5px;
                            color: #ffffff;
                            font-size: 1rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 25px;
                            -webkit-line-clamp: 3;
                            height: 75px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .slick-arrow {
                    color: #2049EF !important;
                }
            }
        }

    }
}

.center {
    transform: scale(1.08) !important;
}

@media only screen and (max-width: 1600px) {
    .planning_description {
        -webkit-line-clamp: 2 !important;
        height: 50px !important;
    }
}
@media only screen and (max-width: 768px) {
    .planning_description {
        -webkit-line-clamp: 3 !important;
        height: 75px !important;
    }
}
@media only screen and (max-width: 500px) {
    .planning_description {
        -webkit-line-clamp: 2 !important;
        height: 50px !important;
    }
}

.link__ {
    margin: 3rem ;
    h3 {
        color: #3342B9;
        text-align: center;
    }
}
.tools-box-right-container {
    top: 10px;
    right: 10px;
    z-index: 50;
    display: flex;
    background-color: #ffffff;
    border-radius: 4px;
    height: 40px;
    align-items: center;

    button {
        border: 0;
        background-color: #ffffff;

        &:focus {
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
    }

    .search-location {
        img {
            width: 16px;
        }
    }

    .dropdown-info {
        min-width: 350px;
        top: 46px;
        right: -86px;
    }

    .close-current-layer-view {
        z-index: 10;
        right: -114px;
        top: 14px;
    }

    .dropdown-base-map {
        right: 0;
        top: 46px;
        .dropdown-item {
            cursor: pointer;
        }
    }

    .dropdown-search {
        right: -166px;
        top: 50px;

        img {
            width: 32px;
            height: 32px;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
                transition: all 0.3s ease;
            }
        }

        input {
            width: 270px;
            border: 0;

            &::placeholder {
                font-size: 14px;
            }
        }
    }
}
.base_general_layer {
    border-top: 1px solid #cdcdcd !important;
}
@media only screen and (max-width: 500px) {
    .tools-box-right-container {
        top: -43px;
    }
}
.general-planning-map-view-container {
    .left-custom-navbar {
        transition-duration: 0.5s;
        top: 0px;

        .custom-button-left-menu {
            top: 10px;
            left: 330px;
            background: linear-gradient(270deg, rgba(26,119,199,1) 0%, rgba(0,182,222,1) 100%);
            padding: 10px;
            border: unset;
            box-shadow: 0px 0px 4px 0px #1A77C7;
            color: #fff;
            img {
                width: 18px;
            }
        }

        .custom-sub-map-tools-panel {
            z-index: 50;
        }
    }

    .custom-position-filter-infomation-popup {
        top: 20px;
        right: 120px;
    }

    .map-tools-container {
        .map-tool-panel-container {
            top: 50px;
            right: 10px;
        }
    }

    .left_menu_button {
        position: absolute;
        right: -54px;
        top: 65px;

        .active {
            box-shadow: 0px 0px 4px 0px #1A77C7;
            background: linear-gradient(90deg, #1A77C7 0%, #00B6DE 100%);
            svg {
                color: #fff !important;
            }
        }

        .left_menu_button_item {
            background-color: #fff;
            border-radius: 10%;

            &:hover {
                box-shadow: 0px 0px 4px 0px #1A77C7;
                background: linear-gradient(90deg, #1A77C7 0%, #00B6DE 100%);

                span {
                    box-shadow: unset !important;
                    background: unset !important;
                }

                svg {
                    color: #fff !important;
                }
            }
        }

        button {
            padding: 15px 10px !important;
            border-radius: 10% !important;
            display: block;
            margin: 1rem 0;
            //background-color: #fff;
        }
    }
}